import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        selectedManagers: [],
    },

    getters: {
        getSelectedIds(state) {
            return state.selectedManagers.map((manager) => manager.id_manager)
        },

        getCompletedIds(state) {
            return state.selectedManagers
                .filter((manager) => manager.is_completed === 1)
                .map((manager) => manager.id_manager);
        },
    },

    mutations: {
        setSelectedManagers(state, value) {
            state.selectedManagers = value;
        },

        changeManagersById(state, ids) {
            state.selectedManagers = ids.map((id) => {
                return {
                    id_manager: id,
                    is_completed: 0,
                }
            });
        },

        changeCompletedManagers(state, ids) {
            state.selectedManagers = state.selectedManagers.map((manager) => {
                if (ids.includes(manager.id_manager)) {
                    manager.is_completed = 1;
                } else{
                    manager.is_completed = 0;
                }
                return manager;
            });
        },

        removeSelectedManagers(state) {
            state.selectedManagers = [];
        },
    },
});

export default store;

import axios from "axios";

export default {
    addManagers(idOrder, idManagers) {
        return axios.post('/api/follow_order/add_managers', {
            'id_order': idOrder,
            'id_managers': idManagers,
        })
            .then(res => res.data)
            .then(() => alert('Менеджеры назначены!'))
            .catch(() => alert('Произошла ошибка'))
    },

    getManagers() {
        return axios.get('/api/follow_order/managers', {

        }).then(data => data.data)
            .catch(() => alert('Произошла ошибка'));
    },

    getManagersForOrder(idOrder) {
        return axios.get('/api/follow_order/managers/search', {
            params: {
                'id_order': idOrder,
            }
        })
            .then(res => res.data)
            .catch(() => alert('Произошла ошибка'));
    },
}
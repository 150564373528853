import { render, staticRenderFns } from "./FollowPopup.vue?vue&type=template&id=4738fe65&scoped=true&"
import script from "./FollowPopup.vue?vue&type=script&lang=js&"
export * from "./FollowPopup.vue?vue&type=script&lang=js&"
import style0 from "./FollowPopup.vue?vue&type=style&index=0&id=4738fe65&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4738fe65",
  null
  
)

export default component.exports
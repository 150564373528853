<template>
  <form class="form" @submit.prevent="sendManagers">
    <div class="title">Заказ №{{ idOrder }}</div>
    <Manager
        v-for="(manager) in managers"
        :id="manager.id"
        :name="manager.name"
        :key="manager.id"
    />
    <div class="wrapper-buttons">
      <button type="submit" class="send-button">Назначить</button>
      <button type="button" class="remove-button" @click="removeSelectedManagers">Сбросить</button>
    </div>
  </form>
</template>

<script>
  import Manager from './Manager';
  import api from '../api';
  import {mapMutations, mapState} from "vuex";

  export default {
    name: 'FollowPopup',

    components: {
      Manager,
    },

    data() {
      return {
        idOrder: null,
        managers: [],
      }
    },

    computed: {
      ...mapState(['selectedManagers']),
    },

    methods: {
      ...mapMutations(['setSelectedManagers', 'removeSelectedManagers']),

      sendManagers() {
        api.addManagers(this.idOrder, JSON.stringify(this.selectedManagers));
      },
    },

    watch: {
      async idOrder(newValue) {
        const managers = await api.getManagersForOrder(newValue);
        this.setSelectedManagers(managers);
      },
    },

    async mounted() {
      this.managers = await api.getManagers();
    },
  }
</script>

<style scoped>
  .wrapper-buttons{
    display: flex;
    gap: 15px;
    align-items: center;
    margin-top: 20px;
  }

  .form{
    padding: 50px;
    background-color: #fff;
    border: 1px solid #333;
    box-shadow: 0 0 10px #333;
    border-radius: 10px;
    max-width: 700px;
  }

  .send-button,
  .remove-button{
    cursor: pointer;
    padding: 10px;
  }

  .title{
    font-size: 18px;
    margin-bottom: 15px;
  }
</style>
<template>
  <div class="wrapper-manager">
    <input
        type="checkbox"
        class="checkbox"
        :id="id"
        :name="id"
        :value="id"
        v-model="checkedIds"
    >
    <label :for="id" class="checkbox-label">
      {{ name }}
    </label>
    <label :for="getIdForCompletedCheckbox" class="checkbox-label">
      Завершен:
    </label>
    <input
        type="checkbox"
        class="checkbox"
        :id="getIdForCompletedCheckbox"
        :name="id"
        :value="id"
        v-model="completedIds"
        :disabled="!isSelectedId"
    >
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

  export default {
    name: 'Manager',
    props: {
      id: {
        type: Number,
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
    },

    computed: {
      ...mapGetters([
          'getSelectedIds',
          'getCompletedIds',
      ]),

      getIdForCompletedCheckbox() {
        return this.id + 'completed';
      },

      isSelectedId() {
        return this.getSelectedIds.includes(this.id);
      },

      checkedIds: {
        get: function () {
          return this.getSelectedIds;
        },
        set: function (newValue) {
          this.changeManagersById(newValue);
        }
      },

      completedIds: {
        get: function () {
          return this.getCompletedIds;
        },
        set: function (changedIds) {
          this.changeCompletedManagers(changedIds)
        }
      }
    },

    methods: {
      ...mapMutations(['changeManagersById', 'changeCompletedManagers']),
    },
  }
</script>

<style scoped>
  .wrapper-manager{
    display: flex;
    align-items: center;
  }
  .checkbox{
    width: 18px;
    height: 18px;
    cursor: pointer;
    position: initial;
  }

  .checkbox-label{
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
  }
</style>